<template>
    <div class="view-page" v-if="hasTicket('acm')">
        <div class="page-header">
            <h1>
                締管理 α
            </h1>
        </div>

        <div>
            <table>
                <tbody v-for="(d, ix) in payload_data" :key="ix">
                    <tr>
                        <th class="rownum" rowspan="2">
                            {{ ix + 1 }}
                        </th>
                        <td>
                            <div class="title">
                                年月
                            </div>
                            <div class="value">
                                {{ d.acm_ym }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                ステータス
                            </div>
                            <div class="value">
                                {{ toNameStatus(d.acm_status) }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                締め処理日時
                            </div>
                            <div class="value">
                                {{ d.acm_timestamp | datetime }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                締め処理者
                            </div>
                            <div class="value">
                                {{ d.acm_account_id }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                該当売上件数
                            </div>
                            <div class="value">
                                {{ d.acm_sales_count }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                該当原価件数
                            </div>
                            <div class="value">
                                {{ d.acm_costofsales_count }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                備考
                            </div>
                            <div class="value">
                                {{ d.acm_note }}
                            </div>
                        </td>
                        <td>
                            <button
                                :disabled="
                                    d.acm_status != 0 && d.acm_status != 2
                                "
                                @click="openClosingDialog(d)"
                            >
                                締める
                            </button>
                        </td>
                        <td>
                            <button
                                :disabled="d.acm_status != 3"
                                @click="openCancellationDialog(d)"
                            >
                                解除する
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <OpModal
            v-if="visibleCloseConfirm"
            title="締め処理"
            @close="visibleCloseConfirm = false"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                :confirm="selectedClosingMessage()"
                complete="締めました。"
                :okfunction="doClosing"
                @fin="close"
                @step="step"
            >
                <div>
                    <OpTextField
                        type="textarea"
                        label="備考"
                        v-model.trim="selectedItem.acm_note"
                        maxlength="1000"
                        name="customer_name_short"
                        @changed-valid="changedValid"
                    />
                </div>
            </OpConfirm>
        </OpModal>
        <OpModal
            v-if="visibleCancelConfirm"
            title="締め解除処理"
            @close="visibleCancelConfirm = false"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                :confirm="selectedCancellationMessage()"
                complete="解除しました。"
                :okfunction="doCancel"
                @fin="close"
                @step="step"
            >
                <div>
                    <OpTextField
                        type="textarea"
                        label="備考"
                        v-model.trim="selectedItem.acm_note"
                        maxlength="1000"
                        name="customer_name_short"
                        @changed-valid="changedValid"
                    />
                </div>
            </OpConfirm>
        </OpModal>
    </div>
</template>

<script>
import DateUtils from "@/DateUtils.js";
import EditorMixin from "@/views/EditorMixin.vue";
import OpConfirm from "@/components/OpConfirm.vue";

export default {
    name: "AclosingList",
    mixins: [EditorMixin],
    components: {
        OpConfirm
    },
    data: function() {
        return {
            payload_data: [],
            selectedItem: null,
            visibleCloseConfirm: false,
            visibleCancelConfirm: false
        };
    },
    methods: {
        selectedClosingMessage: function() {
            return (
                this.selectedItem.acm_ym +
                "の締め処理を実行します。よろしいですか？"
            );
        },
        selectedCancellationMessage: function() {
            return (
                this.selectedItem.acm_ym +
                "の締め解除処理を実行します。よろしいですか？"
            );
        },
        toNameStatus: function(code) {
            let ret = "";
            switch (code) {
                case 0:
                    ret = "未締";
                    break;
                case 1:
                    ret = "処理中";
                    break;
                case 2:
                    ret = "解除中";
                    break;
                case 3:
                    ret = "締済";
                    break;
            }
            return ret;
        },
        openClosingDialog: function(d) {
            this.selectedItem = { ...d };
            this.visibleCloseConfirm = true;
        },
        openCancellationDialog: function(d) {
            this.selectedItem = { ...d };
            this.visibleCancelConfirm = true;
        },
        doClosing: function(func) {
            console.log("★ " + this.selectedItem.acm_note);
            let data = this.convertToRequestData(this.selectedItem);
            let url = this.makeurl("acm", "close", this.selectedItem.acm_ym);
            console.log("★ " + data);
            // this.requesting = true;
            this.putData(url, data, d => {
                func();
                this.selectedItem = d;
                // this.visibleCloseConfirm = false;
                this.search();
            }).then(() => {
                this.requesting = false;
            });
        },
        doCancel: function(func) {
            let data = this.convertToRequestData(this.selectedItem);
            let url = this.makeurl("acm", "open", this.selectedItem.acm_ym);
            // this.requesting = true;
            this.putData(url, data, d => {
                func();
                this.selectedItem = d;
                // this.visibleCancelConfirm = false;
                this.search();
            }).then(() => {
                this.requesting = false;
            });
        },
        search: function() {
            return this.readDataToArray(this.makeurl("acm"), list => {
                let ret = [];
                let fday = DateUtils.getNowDate();
                fday.setDate(1);
                for (let i = 0; i < 12; i++) {
                    let dy = DateUtils.cloneDate(fday);
                    dy.setMonth(fday.getMonth() - i);
                    let ym = DateUtils.toYm6(dy);
                    let dt = {
                        acm_ym: ym,
                        acm_status: 0,
                        acm_timestamp: null,
                        acm_account_id: null,
                        acm_sales_count: 0,
                        acm_costofsales_count: 0,
                        acm_note: null,
                        row_version: 0
                    };
                    for (let item of list) {
                        if (item.acm_ym == ym) {
                            dt = item;
                            break;
                        }
                    }
                    ret.push(dt);
                }
                this.payload_data = ret;
            });
        }
    },
    created: async function() {
        await this.search();
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid gray;
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}
/* .view-header {
    position: sticky;
    top: 0;
    padding: 0px 100px 5px 100px;
    background-color: var(--glass-pane-bg-color);
    z-index: 1; 
} */
/* .view-header > div {
    margin-right: auto;
    margin-left: auto;

} */

/* .add-button {
    width: 30px;
    height: 30px;
    padding: 5px;
} */

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
td .item {
    display: inline-block;
    vertical-align: top;
    padding: 2px 10px;
}
tbody {
    cursor: pointer;
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
tbody tr td {
    border-style: none;
}

tbody {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}
.alegend {
    margin: 15px auto;
    text-align: center;
    font-size: 0.75rem;
    color: var(--color-gray);
}

.future {
    opacity: 0.5;
}
.past {
    opacity: 0.5;
}

.item {
    padding: 10px;
}
.cust-attr .item > * {
    padding: 0 10px;
}
</style>
